<template>
    <div>
        <v-overlay :value="overlay" absolute style="z-index:999999999999">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-data-table
            :headers="headers"
            :items="etiqueta"
            class="elevation-1"
            small
            :search="search"
            item-key="etiqueta_whatsappi_id"
        >
            <template v-slot:top>
                <v-col cols="12" md="12">
                    <v-row>
                        <v-col cols="12" sm="3">
                            <v-autocomplete
                                label="Tipo de etiqueta"
                                v-model="enum_id"
                                :items="tiposEtiqueta"
                                dense
                                item-value="enum_id"
                                item-text="description"
                                hide-details
                                @change="listarEtiquetas()"
                            />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-autocomplete
                                label="Estado"
                                v-model="estadoEtiqueta"
                                :items="estadosEtiqueta"
                                dense
                                item-value="estado"
                                item-text="description"
                                hide-details
                                @change="listarEtiquetas()"
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field 
                                dense
                                v-model="search" 
                                hide-details 
                                label="Buscar" 
                            />
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-btn 
                                @click="nuevaEtiqueta(null)" 
                                color="blue" 
                                small 
                                dark
                            >
                                <v-icon>mdi-plus</v-icon> Crear
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </template>
            <template v-slot:item.opciones="{ item }">
                <v-btn
                    small
                    text
                    @click="deshabilitarEtiqueta(item)"
                    :color="item.thru_date ? 'success' : 'error'"
                    dark
                >
                    <v-icon small>
                       {{ item.thru_date ? 'mdi-check' : 'mdi-delete' }}  
                    </v-icon>
                </v-btn>
                <v-btn icon small color="warning" @click="editarEtiqueta(item)">
                    <v-icon small color green>mdi-pencil</v-icon>
                </v-btn>
            </template>
            <template v-slot:item.color="{ item }">
                    <v-icon :color="item.color"  >mdi-circle</v-icon>
            </template>
        </v-data-table>
    
        <!-- DIALOGO PLANTILLA -->
        <v-dialog v-model="dialogoEtiqueta" persistent width="700">
            <v-form ref="formEtiqueta">
            <v-card>
                <v-toolbar dark color="blue" height="40" flat>
                    <v-icon left>mdi-tag</v-icon>
                        Etiqueta
                    <v-spacer></v-spacer>
                    <v-btn small text @click="dialogoEtiqueta = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="m-4">
                    <v-row>
                        <v-col md="5">
                            <v-text-field
                                :rules="requiredRule"
                                v-model="datosEtiqueta.description"
                                label="Nombre"
                                hide-details="auto"
                                dense
                            /> 
                        </v-col>
                        <v-col md="5">
                            <v-autocomplete
                                label="Tipo de etiqueta"
                                v-model="datosEtiqueta.enum_id"
                                :items="tiposEtiqueta.filter(e => e.enum_id)"
                                dense
                                item-value="enum_id"
                                item-text="description"
                                hide-details="auto"
                            />
                        </v-col>
                        <v-col md="2">
                            <v-text-field
                                type="color"
                                label="Color"
                                v-model="datosEtiqueta.color"
                                dense
                                hide-details="auto"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn class="mr-4" color="primary" small @click="guardarEtiqueta">
                        <v-icon>mdi-content-save</v-icon>Guardar
                    </v-btn>
                    <v-btn class="mx-4" text small @click="dialogoEtiqueta = false">
                        <v-icon>mdi-content-cancel</v-icon>Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
            </v-form>
        </v-dialog>  
    </div>
  </template>
  
  <script>
  import { mapState, mapMutations, mapActions } from "vuex";
  import Vue from "vue";
  
  export default {
    name: "EtiquetasWa",
    data: () => ({
      requiredRule: [(v) => !!v || "El campo es requerido"],
      selected: [],
      users: [],
      enum_id:null,
      search:null,
      tiposEtiqueta:[{enum_id:null, description:'Todas'}],
      required: [(v) => !!v || "El campo es requerido"],
      headers: [
        { text: "Opciones", align: "start", value: "opciones", width: 120 },
        { text: "ID", align: "start", value: "etiqueta_whatsappi_id" },
        { text: "Nombre", align: "start", value: "description" },
        { text: "Tipo", align: "start", value: "tipo_description" },
        { text: "Color", align: "start", value: "color" }
      ],
      dialogoEtiqueta: false,
      datosEtiqueta: {
        etiqueta_whatsappi_id: null,
        description: null,
        tipo: null,
        color:null
      },
      estadosEtiqueta:[
        {estado : 'ACTIVE', description : 'Activa'},
        {estado : 'INACTIVE', description : 'Inactiva'}
      ],
      estadoEtiqueta:'ACTIVE',  
      crearEtiqueta: false,
      etiqueta: [],
      dialogWidth: 400,
      overlay:false
    }),
    computed: {    

      ...mapState("master", ["loadingBtn", "companies","token", "tenantId", "user", "url" ]),
      ...mapState("crm", ["menu", "loadingBtn", "paramAlertQuestion"]),    

    },
    methods: {

        ...mapMutations("master", [
            "setUrl",
            "setLoadingTable",
            "setTitleToolbar",
            "setTenantId",
        ]),
    
        ...mapActions("master", ["requestApi", "alertNotification"]),
        ...mapActions("access", []),
    
        getTipoEtiquetas(){

            this.setUrl("etiquetas-whastappi");
    
            this.requestApi({
                method: "GET",
                data: {
                    typeList: 'tipoEtiqueta'
                },
            })
            .then((res) => {

                this.tiposEtiqueta.push(...res.data.detail)
            
            })
            .then(() => {
            this.setLoadingTable(false);
            })
        
        },

        listarEtiquetas() {

            this.overlay = true
    
            this.setUrl("etiquetas-whastappi")
    
            this.requestApi({
            method: "GET",
            data: {
                typeList: 'listEtiquetas',
                estadoEtiqueta: this.estadoEtiqueta,
                enum_id: this.enum_id
            },
            })
            .then(res => {

                this.etiqueta = res.data.detail
            
            })
            .then(() => {
                
                this.overlay = false

            })
        },
    
        nuevaEtiqueta() {
    
            this.dialogoEtiqueta = true;
            
            this.datosEtiqueta = {
                etiqueta_whatsappi_id: null,
                description: null,
                enum_id: null,
                color:null
            }
        
        },

        editarEtiqueta(item) {

            this.dialogoEtiqueta = true
            
            this.datosEtiqueta = {
                etiqueta_whatsappi_id: item.etiqueta_whatsappi_id,
                description: item.description,
                enum_id: item.enum_id,
                color:item.color
            }

        },
    
        guardarEtiqueta() {
    
            if (!this.$refs.formEtiqueta.validate()) 
                return false
            
            this.overlay = true

            this.setUrl("etiquetas-whastappi")
        
            this.requestApi({
                method: "POST",
                data: {
                    accion: "storeEtiquetaWhatsappi",
                    ...this.datosEtiqueta
                },
            })
            .then(res => {
                console.log('res',res)
                this.dialogoEtiqueta = false
                this.alertNotification({
                    param: {
                        html: res.data.detail
                    }
                })

                this.listarEtiquetas()
                
            })
            .then(() => {

                this.setLoadingTable(false)

            })

        },

        deshabilitarEtiqueta(item) {

            Vue.swal({
                html: "Está seguro de deshabilitar esta etiqueta?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cerrar",
                ...this.paramAlertQuestion,
            }).then((result) => {

                if (result.isConfirmed) {

                    this.setLoadingTable(true);
                    this.setUrl("etiquetas-whastappi")

                    this.requestApi({
                        method: "POST",
                        data: {
                            accion: "updateStatusEtiquetaWhatsappi",
                            etiqueta_whatsappi_id: item.etiqueta_whatsappi_id,
                            status: item.thru_date == null ? 'INACTIVE' : 'ACTIVE'
                        },
                    })
                    .then((res) => {

                        this.listarEtiquetas()
                        this.dialogoEtiqueta = false

                    })
                    .then(() => {

                        this.setLoadingTable(false)

                    })


                }

            })

        },

    },
    mounted() {

        this.setTitleToolbar("ETIQUETAS WHATSAPPI")

        this.getTipoEtiquetas()
        this.listarEtiquetas()

    }

  }
  </script>
  